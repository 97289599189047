@import url('https://fonts.googleapis.com/css?family=Muli:700');

html,
body,
#root {
  margin: 0;
  padding: 0;

  width: 100%;
  height: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 300;
  font-size: 14px;
  text-rendering: optimizeLegibility;

  background: #000;
  color: #fff;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

#root {
  height: 100vh;
  display: flex;
}
